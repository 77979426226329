<template>
  <div class="scoreboard">
    <div v-for="(placement, index) in members" v-bind:key="index">
      <h1 v-if="index == 0">Place {{index + 1}} 👑</h1>
      <h1 v-else>Place {{index + 1}}</h1>

      <div v-for="member in placement" v-bind:key="member.name">

        <span v-for="(solve, index) in member.solves" v-bind:key="index" class="star" :class="solve">
          *
        </span>
        <span class="spacer"></span>
        <span class="name" v-if="!!member.name">{{member.name}}</span>
        <span class="name" v-else>🍞 #{{member.id}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import arrayShuffle from 'array-shuffle';

export default {
  name: 'ScoreBoard',
  data() {
    return {
      members: [],
    }
  },
  mounted() {
    fetch('https://cdn.adventboard.xyz/cdn-adventboard-xyz/tue.json')
    .then(response => response.json())
    .then(json => {
      let members = {};

      for (const [key, value] of Object.entries(json.members)) {
        let member = {};
        member.name = value.name;
        member.id  = value.id;
        member.solves = new Array(25).fill('no-star');
        member.stars = value.stars;

        for (const[day, result] of Object.entries(value.completion_day_level)) {
          const daily = Object.keys(result).length;
          if (daily === 0) continue;

          member.solves[day - 1] = daily === 2 ? 'gold' : 'silver';
        }

        if (!(member.stars in members)) {
          members[member.stars] = [];
        }

        members[member.stars].push(member);
      }

      const order = Object.keys(members).map(k => Number(k)).sort((a, b) => a > b ? -1 : 1);
      let result = [];

      order.forEach(entry => {
        result.push(arrayShuffle(members[entry]));
      });

      this.members = result;
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.star {
  font-size: 20px;
  color: #333333;
}

.gold {
  color: #ffff66;
}

.silver {
  color: #9999cc;
}

.name {
  font-size: 25px;
  margin-left: 20px;
}

</style>
