<template>
  <div id="app">
    <ScoreBoard/>
  </div>
</template>

<script>
import ScoreBoard from './components/ScoreBoard.vue';

export default {
  name: 'App',
  components: {
    ScoreBoard
  },
  metaInfo: {
    title: 'Advent of Code Board'
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 700px;
  margin: 0 auto;
  margin-top: 60px;
}

body {
  background-color: #0f0f23;
  color: #cccccc;
  font-family: "Source Code Pro", monospace;
}
</style>
